export default {
  props: {
    lazilyMountOptions: {
      type: Object,
      default: () => ({
        rootMargin: '500px 0px 500px 0px',
        threshold: 0.0
      })
    }
  },
  data() {
    return {
      mountedLazily: false,
      observer: null
    };
  },
  render(h) {
    if (
      !this.mountedLazily ||
      (!this.$scopedSlots.default && !this.$slots.default)
    ) {
      return h('div', {
        style: {
          minHeight: '500px'
        }
      });
    }

    console.log('slot mounted');
    if (this.observer) {
      this.observer.disconnect();
    }

    return this.$slots.default[0];
  },
  errorCaptured: function(err, vm) {
    this.$el.removeAttribute('style');
    console.error('Lazy Mounting failed for component\n', vm && vm._name);
  },
  mounted() {
    try {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver(
          entries => {
            this.mountedLazily =
              this.mountedLazily || (entries[0] && entries[0].isIntersecting);
          },
          this.lazilyMountOptions
        );

        this.observer.observe(this.$el);
      } else {
        console.debug(
          `This browser doesn't support IntersectionObserver. Please use polyfill.
         https://github.com/GoogleChromeLabs/intersection-observer.`
        );
        this.mountedLazily = true;
      }
    } catch (e) {
        this.$el.removeAttribute('style');
    }
  },
  destroyed() {
    this.observer.disconnect();
  }
};
