//
//
//
//

import Vue from 'vue';
import {mapState} from 'vuex';

export default Vue.extend({
  name: 'AdobeMbox',
  props: {
    id: {
      type: String,
      default: ''
    },
    placeholderType: {
      type: String,
      default: ''
    },
    mbox: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['scriptLoaded']),
    mboxName() {
      /*
      * Search corresponding mbox in placeholders first, if it's not present then consider the id as mboxName
      * */
      return this.mbox || this.id;
    },
  },
  mounted() {
    this.fetchAdobeMBox();
  },
  watch: {
    'scriptLoaded.satelliteScript': {
      handler(newValue) {
        if(newValue === true && process.client) {
          this.fetchAdobeMBox();
        }
      },
      flush: 'post',
      deep: true
    }
  },
  methods: {
    fetchAdobeMBox() {
      let el = this.$el;
      const mboxName = this.mboxName;
      try {
        // eslint-disable-next-line no-undef
        adobe.target.getOffer({
          mbox: mboxName,
          success: function (offer) {
            // eslint-disable-next-line no-undef
            adobe.target.applyOffer({
              mbox: mboxName,
              selector: el,
              offer: offer
            });
            if (offer.length === 0) {
              el.closest('.dre-group').classList.add('dre-group--hidden');
            }
          },
          error: function (error) {
            console.error(error);
            el.style.visibility = 'visible';
            el.closest('.dre-group').classList.add('dre-group--hidden');
          }
        });
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  },
  errorCaptured: function(err) {
    console.log(`Caught error from AdobeMbox component:: ${err.message}`);
    this.$sentry.captureException(new Error(`Caught error from AdobeMbox component:: ${err.message}`))
  },
});
