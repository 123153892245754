//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import PlaceHolderMixin from '@/components/mixin/PlaceHolderMixin';
import LPPlaceholderMixin from '@/components/mixin/LPPlaceholderMixin';
import adobeMbox from '../placeholders/adobeMbox.vue';
import LazyHydrate  from 'vue-lazy-hydration';
export default {
  name: 'BottomPart',
  components: { adobeMbox, LazyHydrate },
  mixins: [PlaceHolderMixin, LPPlaceholderMixin],
  computed: {
    placeholders() {
      return this.bottomEditionPlaceHolders.filter((placeholder) => {
        return this.hasDynamicComponent(placeholder) === true;
      });
    },
    ...mapState(['bottomEditionPlaceHolders']),
  },
  errorCaptured: function(err) {
    console.error(`Caught error from children of BottomPart component:: ${err.message}`);
  },
};
