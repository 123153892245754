import {mapState} from 'vuex';

export default {
  data: () => ({
    errorMessage: '',
    placeholderComponentName: {
      'lantern_placeholder_most-read': {
        name: 'MostRead',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_d2-front': {
        name: 'D2Front',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_meninger-front': {
        name: 'MeningerFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_magasinet-front': {
        name: 'MagasinetFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_smak-front': {
        name: 'SmakFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_norkon_and_investor_news': {
        name: 'DnNorkonInvestorNews',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_lp_recommendation-front': {
        name: 'LPRecommendationFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_dn_real_estate_robot-front': {
        name: 'DnRealEstateRobotFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_dn_job_portal-front': {
        name: 'DNJobPortalFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_lp_recommendation-front-1': {
        name: 'LPRecommendationFront1',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_lp_recommendation-front-2': {
        name: 'LPRecommendationFront2',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_lp_recommendation-front-2-with-ad': {
        name: 'LPRecommendationFront2WithAd',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_lp_recommendation-front-3': {
        name: 'LPRecommendationFront3',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_everviz-embed-1': {
        name: 'EvervizEmbed',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_everviz-embed-2': {
        name: 'EvervizEmbed',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_wine_search_embed': {
        name: 'WineSearch',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_dnx-front-element': {
        name: 'DnxFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_dnx-front-mixed-element': {
        name: 'DnxFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_live-centers-1': {
        name: 'LiveCenters',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_live-centers-2': {
        name: 'LiveCenters',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_podcast': {
        name: 'Podcast',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_embed_content': {
        name: 'EmbedContent',
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_embed_content_2': {
        name: 'EmbedContent',
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_fantasyfond': {
        name: 'FantasyFond',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      },
      'lantern_placeholder_dntv': {
        name: 'DNTVFront',
        clientOnly: true,
        lazilyMountOptions: {
          shouldLazilyMount: true,
        }
      }
    },
  }),
  computed: {
    ...mapState(['nodeEnv'])
  },
  errorCaptured(err, vm, info) {
    const message = `Something went wrong at ${vm.$options._componentTag}, error: ${err.toString()}, info: ${info}`;
    if (this.nodeEnv !== 'prod' && process.client) {
      this.errorMessage = message;
    }
    console.log(message)
    return false;
  },
  methods: {
    getDynamicComponentName(placeholder) {
      if (placeholder.type === 'sphynx-ad') {
        if(placeholder.id === 'custom_content' || placeholder.id === 'custom_content_2') {
          return 'customAdContent';
        }
        return 'liveWrappedAd';
      }
      if(placeholder.placeholder === 'lantern_placeholder_subscriber-engagement') {
        return 'adobeMbox';
      }
      return this.placeholderComponentName[placeholder.id].name;
    },
    hasDynamicComponent(placeholder) {
      return (placeholder.type === 'sphynx-ad') ||
        !!(placeholder && placeholder.placeholder === 'lantern_placeholder_subscriber-engagement') ||
        !!(
        placeholder &&
        placeholder.id &&
        this.placeholderComponentName[placeholder.id]
      );
    },
    isLazyHydrateComponent(placeholder) {
      return !!(placeholder && placeholder.id
        && this.placeholderComponentName[placeholder.id] &&
        this.placeholderComponentName[placeholder.id].lazyHydrate && this.placeholderComponentName[placeholder.id].lazyHydrate.option);
    },
    getLazyHydrateProp(placeholder) {
      const option = this.isLazyHydrateComponent(placeholder) && this.placeholderComponentName[placeholder.id].lazyHydrate.option;
      const prop = {};
      if(option) {
        prop[option] = true;
      }
      return prop;
    },
    isLazilyMountComponent(placeholder) {
      return !!(placeholder && placeholder.id
        && this.placeholderComponentName[placeholder.id] &&
        this.placeholderComponentName[placeholder.id].lazilyMountOptions && this.placeholderComponentName[placeholder.id].lazilyMountOptions.shouldLazilyMount);
    },
    isClientOnlyComponent(placeholder) {
      return placeholder.type === 'sphynx-ad' ||
        !!(placeholder && placeholder.id
        && this.placeholderComponentName[placeholder.id] &&
        this.placeholderComponentName[placeholder.id].clientOnly);
    }
  },
};
