import {mapState} from 'vuex';

export default {
  data: () => ({
    lpConfig: {
      apiMode:true, debug: !(process.env.NODE_ENV && process.env.NODE_ENV === 'prod') , engine:'basic', render:{}, viewThreshold:3,
      streamConfigUrl: 'https://streams-cdn.k5a.io/streams/v1',
      streamUrl : 'https://api-dn.nhst.tech/v1/kilkaya/recommendation'
    },
    lpPlaceholdersArticleCount: {
      'lantern_placeholder_lp_recommendation-front-1' : 5, 'lantern_placeholder_lp_recommendation-front-2' : 5,
      'lantern_placeholder_lp_recommendation-front-2-with-ad' : 3, 'lantern_placeholder_lp_recommendation-front-3' : 6,
    },
  }),
  computed: {
    ...mapState(['articleIdsToFilter', 'bottomEditionPlaceHolders']),
  },
  methods: {
    getAdobeSegment() {
      let theSegment = 'dnfront-generic';
      // dont delete this code=, we will need re-enable this in future
      /*if (window._satellite?.cookie !== undefined && window._satellite?.cookie !== null) {
        const cookieVal = window._satellite.cookie.get('lpsegments');
        const analyticsPrivacySetting = window._satellite.getVar('analyticsPrivacySetting');
        const relevantNewsDnPrivacySettting = window._satellite.getVar('relevantNewsDnPrivacySetting');

        if(analyticsPrivacySetting === 'analyticsOptOut' || relevantNewsDnPrivacySettting === 'relevantNewsDnOptOut'){
          return theSegment;
        }
        else{
          if (typeof cookieVal === 'string' && cookieVal.length > 0) {
            var segments = cookieVal.replace(/^.+=(.+)$/, '$1').split(':');
            if (segments.length > 0) {
              theSegment = segments[0];
            }
          }
        }
      }*/
      return theSegment;
    },
    getLanternIDFromUrl(url) {
      return url.includes('dntv.dn.no/m') ?
        this.getDntvLanternId(url) : url.substring(url.lastIndexOf('/') + 1);
    },
    getDntvLanternId(url) {
      const urlParts = url.split('/');
      const dntvId = urlParts[urlParts.indexOf('m') + 1];
      return '6-1-' + dntvId;
    },
    getLPArticleFieldValue(article, fieldName, fallbackFieldName) {
      return article.lanternprops && article.lanternprops[fieldName] ? article.lanternprops[fieldName] : article[fallbackFieldName];
    },
    getLPShortenedUrl(article) {
      return article['url'];
    },
    getLPArticleImage(article, imageVersion) {
      return article.lanternprops && article.lanternprops.images && article.lanternprops.images[imageVersion]
        ? article.lanternprops.images[imageVersion] + '?image_version=640' : article.image;
    },
    getLPAvailableImageCropSrc(article) {
      return article.lanternprops && article.lanternprops.images;
    },
    getLPArticleUrl(article) {
      const url = this.getLPArticleFieldValue(article, 'canonicalurl', 'url');
      return url.startsWith('http') ? url : ('https://').concat(url);
    },
    getTransformedArticle(article) {
      const topicName = article.lanternprops && article.lanternprops.topic ? article.lanternprops.topic.label : '';
      return {
        id: this.getLanternIDFromUrl(article.url),
        title: this.getLPArticleFieldValue(article, 'headfront', 'title') || '',
        link: this.getLPArticleUrl(article) || '',
        lpUrl: this.getLPShortenedUrl(article),
        image: this.getLPArticleImage(article, 'three_two') || '',
        availableImageCropSrc: this.getLPAvailableImageCropSrc(article) || {},
        titleTeaser: this.getLPArticleFieldValue(article, 'kicker', ''),
        paid: this.getLPArticleFieldValue(article, 'paid') || false,
        topic: {
          name: topicName,
          link: `/topic/${topicName.toLowerCase()}`
        }
      };
    },
    fetchRecommendedArticles() {
      const self = this;
      const idsToFilter = [...self.articleIdsToFilter];
      window.LP4.config.stream = this.lpConfig;
      window.LP.stream.reInit();
      const adobeSegment = this.getAdobeSegment();

      window.LP.stream.initStream(adobeSegment, function (streamName) {
        self.bottomEditionPlaceHolders.forEach((placeholder, index) => {
          if (placeholder.id.startsWith('lantern_placeholder_lp_recommendation-front')) {
            let articles = [];
            let currentNoOfArticleToFetch = self.lpPlaceholdersArticleCount[placeholder.id];
            let noOfTry = 0;
            while (currentNoOfArticleToFetch > 0 && noOfTry < 25) {
              try {
                const LPArticleResponse = window.LP.stream.get(streamName, currentNoOfArticleToFetch) || [];
                let processedArticles = (LPArticleResponse || []).filter(art => !idsToFilter.includes(self.getLanternIDFromUrl(art.url)))
                  // .filter(art => articles.filter((tempArt) => tempArt.id == self.getLanternIDFromUrl(art.url)))
                  .map(self.getTransformedArticle);
                articles.push(...processedArticles);
                processedArticles.forEach(art => idsToFilter.push(self.getLanternIDFromUrl(art.id)));
                currentNoOfArticleToFetch -= processedArticles.length;
                noOfTry++;
              } catch (error) {
                console.error('@@@ Error::', error);
                noOfTry++;
              }
            }
            self.$store.dispatch('addArticlesToLPPlaceholder', {index, articles, adobeSegment});
          }
        })
      });
    }
  },
  mounted() {
    const self = this;
    if (window.LP4 !== undefined && window.LP4 !== null) {
      self.fetchRecommendedArticles();
    } else {
      document.addEventListener('K5A:Ready', function () {
        self.fetchRecommendedArticles();
      });
    }
  },
};
